:root {
  --max-width: 1100px;
  
  --thin-weight: 100;
  --extralight-weight: 200;
  --light-weight: 300;
  --normal-weight: 400;
  --medium-weight: 500;
  --semibold-weight: 600;
  --bold-weight: 700;
  --extrabold-weight: 800;
  --black-weight: 900;

  --base-titles-h1-margin: 1.875rem 0;
  --base-titles-h1-text-align: center;
  --base-titles-h1-text-transform: uppercase;
  --base-titles-h1-font-weight: var(--normal-weight);
  --base-titles-h1-letter-spacing: 0;
  --base-titles-h2-margin-bottom: 1.875rem;
  --base-titles-h2-text-transform: uppercase;
  --base-titles-h2-line-height: 1.625rem;
  --base-titles-h2-font-weight: var(--normal-weight);
  --base-titles-h3-margin-bottom: 0.9375rem;
  --base-titles-h3-text-transform: none;
  --base-titles-h3-line-height: 1.5625rem;
  --base-titles-h4-margin-bottom: 0.8125rem;
  --base-titles-h4-font-style: normal;
  --base-titles-h4-text-transform: none;
  --base-titles-h4-line-height: 1.4375rem;
  --base-titles-h5-line-height: 1.1;
  --base-titles-h5-margin-bottom: 0.625rem;
  --base-titles-h5-text-transform: none;
  --base-titles-h6-line-height: 1.1;
  --base-titles-h6-margin-bottom: 0.625rem;
  --base-titles-h6-text-transform: none;

  --common-transition-time: 0.25s;
  --common-text-font: 'brandon-grotesque', arial, sans-serif;
  --common-text-font-serif: 'calluna', serif;
  --common-text-font-serif-accent: 'Mirador-Book', serif;
  --venia-font: var(--common-text-font);
  --info: 17, 100, 211;
  --error: 204, 0, 51;
  --warning: 255, 204, 0;
  --success: 73, 162, 115;
  --text-color: 0, 0, 0;
  --text-secondary-color: 115, 115, 115;
  --gray: 128, 128, 128;
  --gray2: 170, 170, 170;
  --gray3: 204, 204, 204;
  --light-gray: 228, 229, 229;
  --light-gray2: 238, 238, 238;
  --light-gray3: 249, 249, 249;
  --light-gray4: 242, 242, 242;
  --structube-red: 207, 10, 44;
  --structube-red-darker: 163, 0, 41;
  --black: 0, 0, 0;
  --white: 255, 255, 255;
  --common-text-color: var(--text-color);
  --common-text-lineheight: 1.125;

  --h1-font-size: 2rem;
  --h1-alt-font-size: 4.375rem;
  --h2-font-size: 1.625rem;
  --h2-alt-font-size: 2.625rem;
  --h3-font-size: 1.4375rem;
  --h4-font-size: 1.3125rem;
  --h5-font-size: 1.25rem;
  --h6-font-size: 1.0625rem;

  --button-font-size: 1.25rem;
  --button-small-font-size: 0.9375rem;
  --button-padding: 1.125rem 3.4375rem;
  --button-small-padding: 0.8438rem 1.375rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  width: auto;
  padding: 0;
  margin: 0;
  background-color: var(--white);
  color: rgba(var(--common-text-color));
  font-family: var(--venia-font);
  line-height: var(--common-text-lineheight);
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
h2 {
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
h3 {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
h5 {
  display: block;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
h6 {
  display: block;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.5rem;
  color: rgba(var(--text-color));
  font-size: 1rem;
  font-style: normal;
  text-rendering: optimizeLegibility;
}
h1, .h1, .alt-h1 {
  margin: var(--base-titles-h1-margin);
  font-size: var(--h1-font-size);
  font-weight: var(--base-titles-h1-font-weight);
  text-align: var(--base-titles-h1-text-align);
  text-transform: var(--base-titles-h1-text-transform);
  letter-spacing: var(--base-titles-h1-letter-spacing);
}
h2, .h2, .alt-h2 {
  margin-bottom: var(--base-titles-h2-margin-bottom);
  font-size: var(--h2-font-size);
  font-weight: var(--base-titles-h2-font-weight);
  text-transform: var(--base-titles-h2-text-transform);
  line-height: var(--base-titles-h2-line-height);
}
h3, .h3 {
  margin-bottom: var(--base-titles-h3-margin-bottom);
  font-size: var(--h3-font-size);
  text-transform: var(--base-titles-h3-text-transform);
  line-height: var(--base-titles-h3-line-height);
}
h4, .h4 {
  margin-bottom: var(--base-titles-h4-margin-bottom);
  font-size: var(--h4-font-size);
  font-style: var(--base-titles-h4-font-style);
  text-transform: var(--base-titles-h4-text-transform);
  line-height: var(--base-titles-h4-line-height);
}
h5, .h5 {
  margin-bottom: var(--base-titles-h5-margin-bottom);
  font-size: var(--h5-font-size);
  text-transform: var(--base-titles-h5-text-transform);
  line-height: var(--base-titles-h5-line-height);
}
h6, .h6 {
  margin-bottom: var(--base-titles-h6-margin-bottom);
  font-size: var(--h6-font-size);
  text-transform: var(--base-titles-h6-text-transform);
  line-height: var(--base-titles-h6-line-height);
}

h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-weight: var(--medium-weight);
}

@media only screen and (min-width: 60.0625rem) {
  :root {
      --h1-font-size: 3rem;
      --h2-font-size: 2.3125rem;
      --h3-font-size: 1.875rem;
      --h4-font-size: 1.4375rem;
      --category-sort-title-size: 1.125rem;
      --h1-category-size: 4.375rem;

      --base-titles-h1-letter-spacing: 0.0455em;
      --base-titles-h2-font-weight: var(--normal-weight);
      --base-titles-h2-line-height: 3rem;
      --base-titles-h3-line-height: 2rem;
      --base-titles-h4-line-height: 1.5625rem;
      --base-titles-h5-line-height: 1.375rem;
  }
}