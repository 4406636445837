.error {
    font-weight: var(--semibold-weight);;
    color: rgba(var(--error));
}

.app {
  max-width: 50.9375rem;
  text-align: center;
  margin: 0 auto;
}

.app button {
  -webkit-appearance: none;
  appearance: none;
  padding: 0;
  border: 0;
  background: none;
  font-family: var(--venia-font);
  font-size: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

.app .actions button {
  position: relative;
  display: inline-block;
  border-width: 0.0938rem;
  border-style: solid;
  border-radius: 0;
  margin: 0;
  font-weight: var(--medium-weight);
  padding: var(--button-small-padding);
  font-size: var(--button-small-font-size);
  letter-spacing: 0.05625rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.1;
  letter-spacing: 0.06875rem;
  transition: background-color var(--common-transition-time) ease-out, border-color var(--common-transition-time) ease-out, box-shadow var(--common-transition-time) ease-out;
  cursor: pointer;
}

.resetSpace {
  padding: 0;
  border: 0;
  margin: 0;
  outline: 0;
}

.contentBoxWrapper {
  padding: 0 0.625rem;
  margin-bottom: 1.25rem;
  background-color: rgb(var(--light-gray3));
}
.contentBoxWrapper .contentBox {
  max-width: 40.1875rem;
  margin: 0 auto;
  padding: 1.625rem 0;
  text-align: start;
}
.contentBoxWrapper .contentBox .gridContent {
  display: grid;
  align-items: flex-start;
  justify-content: center;
  -webkit-column-gap: 2rem;
  column-gap: 2rem;
  row-gap: 1rem;
}
.contentBoxWrapper .contentBox .gridContent .fieldWrapper {
  display: grid;
}
.contentBoxWrapper .contentBox .title, .contentBoxWrapper .contentBox .gridContent .fieldWrapper .fieldLabel {
  font-size: var(--h4-font-size);
  margin-bottom: var(--base-titles-h3-margin-bottom);;
  font-weight: var(--medium-weight);
  text-transform: uppercase;
}
.contentBoxWrapper .contentBox .title {
  margin-bottom: 1.5rem;
}
.contentBoxWrapper .contentBox .actions {
  display: grid;
  justify-items: flex-end;
  row-gap: 1rem;
}
.contentBoxWrapper .contentBox .actions button {
  width: 100%;
  border-color: rgb(var(--text-color));
  background-color: rgba(var(--text-color));
  color: rgba(var(--white));
}
.contentBoxWrapper .contentBox .value, .contentBoxWrapper .contentBox .gridContent .fieldWrapper .fieldValue {
  margin-bottom: 1rem;
  color: rgba(var(--text-secondary-color));
  font-weight: var(--medium-weight);
  font-style: var(--base-titles-h4-font-style);
  text-transform: var(--base-titles-h4-text-transform);
  line-height: 1;
  font-size: var(--h4-font-size);
}
.contentBoxWrapper .contentBox .value, .contentBoxWrapper .contentBox .gridContent .fieldWrapper .fieldValue:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 40.0625rem) {
  .contentBoxWrapper .contentBox {
    padding: 3.625rem 0;
  }
  .contentBoxWrapper .contentBox .gridContent {
    grid-template-columns: 1fr 1fr;
  }
  .contentBoxWrapper .contentBox .title, .contentBoxWrapper .contentBox .gridContent .fieldWrapper .fieldLabel {
    font-size: var(--h3-font-size);
  }
  .contentBoxWrapper .contentBox .actions {
    -webkit-column-gap: 2rem;
    column-gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 40rem) {
  .contentBoxWrapper .contentBox .actions button {
      padding: var(--button-padding);
  }
}

.modalContainer {
  position: fixed;
  width: 100%; 
  height: 100%;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal {
  width: 99%;
  padding: 5px;
  border: 5px;  
  background-color: rgba(var(--white));
  box-sizing: border-box;
}
.modal__bottom {
  text-align: center;
  margin: 10px 0 5px;
}
.modal__bottom button.save {
  border-color: rgb(var(--text-color));
  background-color: rgba(var(--text-color));
  color: rgba(var(--white));
  margin-right: 20px;
}
.modal__bottom button.cancel {
  border-color: rgba(var(--black));
  background-color: rgba(var(--white));
  color: rgba(var(--black));
}

.sigPadContainer {
  background-color: rgb(238, 235, 235);
  padding: 0 10px 10px;
}
.sigCanvas {
  margin-top: 10px;
  border: solid 4px black;
  border-radius: 10px;  
  background-color: rgba(var(--white));
}
.sigPadContainer hr {
  margin-bottom: unset;
  background-color: gainsboro;
}
.sigPadContainer button {
  border: none;
  margin-left: auto;
  color: rgb(78, 75, 75);
  padding: 0;
  display: block;
  margin-top: 5px;
  margin-right: 10px;
}

.imageContainer {
  position: relative;
}

.signature {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 4px 2px gainsboro;
  padding: 5px;
  object-fit: contain;
  margin-top: 10px;
}

.contentBoxWrapper .contentBox .imageContainer button.modify {
  position: absolute;
  top: 20px;
  right: 15px;
  text-decoration: underline;
}

.contentBoxWrapper .contentBox .actions button.finalized {
  border-color: rgba(var(--success));
  background-color: rgba(var(--white));
  color: rgba(var(--success));
  font-weight: var(--bold-weight);
}

@media only screen and (max-width: 26.438rem) {
  .modal__bottom button.save {
    margin-right: 0;
  }
  .modal__bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
}

@media only screen and (min-width: 40.0625rem) {
  .modal {
    width: 70%;
    padding: 10px;
  }
  .modal__bottom {
    text-align: right;
    margin: 10px 0 0 0;
  }
}

@media (orientation: landscape) {
  .sigCanvas {
    width: 100%;
    height: 40vh;
  }
}

@media (orientation: portrait) {
  .sigCanvas {
    width: 100%;
    height: 22vh;
  }
}