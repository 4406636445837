main {
    margin: 1rem auto;
    text-align: center;
}

.content {
    margin: 1rem auto;
    text-align: center;
}

.headerContainer {
    margin: 1rem auto;
    text-align: center;
    box-shadow: 0 0.0625rem 0 rgba(195, 195, 195);
}

.headerLogo {
    margin: 1rem 0;
    width: 20rem;
    height: auto;    
}